.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-brown {
  background-color: #564020;
}

.color-brown {
  color: #564020 !important;
}

.brownText {
  font-weight: bold;
  color: #7E4D2D;
  font-size: 14px;
  font-family: gothamXLight;
}

.btn-primary {
  background-color: #98511E;
  border-color: #98511E;
}

.btn-link {
  font-weight: normal;
  font-family: 'gothamXLight';
  font-size: 11px;
  color: #98511E;
  background-color: inherit;
  border: 0px none transparent;
}

.btn-link-large {
  font-weight: normal;
  font-family: 'gothamXLight';
  font-size: 14px;
  color: #98511E;
  background-color: inherit;
  border: 0px none transparent;
}

.btn-primary:hover {
  background-color: #E2931E;
  border-color: #E2931E;
}



label {
  margin-bottom: 0.1rem;
}

.modal-offset {
  margin-top: 20px;
}

.tableHeading {
  padding-top: 25px;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: bold;
  color: #7E4D2D;
}

.bottomBorder {
  border-bottom: 1px solid #D2B883;
}