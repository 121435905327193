
body {
  background-color: '#E5DCB9';
}

.footnote {
  background-color: #F7F1E6;
  font-size: 14px;
  color: #98511E
}


@media only screen and (max-width: 480px) {
  
  .outerBox{
    width:100%;
    display: flex;
    justify-content: center;
  }

}

.mainrow{
  text-align: 'center';
  background-color: '#FFFAF0';
  border-bottom: '1px solid #F4ECDC';
  padding: '0.5vw';
  color: '#91742D';
}


/*
div {
  border: 1px solid black !important;
}
*/

