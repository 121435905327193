
@font-face {
  font-family: gotham;
  src: url(/Gotham-Font/Gotham-Bold.otf);
}

@font-face {
  font-family: gothamXLight;
  src: url(/Gotham-Font/Gotham-XLight.otf);
}

@font-face {
  font-family: gothamLight;
  src: url(/Gotham-Font/Gotham-Light.otf);
}

@font-face {
  font-family: gothamThin;
  src: url(/Gotham-Font/Gotham-Thin.otf);
}

/* Need these to suppress arrows from input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html {
  height: 100%;
  margin: 0;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.opaque{
  opacity: 1;
}

.parallax { 

  /* Full height */
  height: 100%; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.faqbox {
  text-align: left;
  border-radius: 25px;
  background: #FCD576;
  margin: 20px;
  padding: 20px;
  width: 400px;
}

.faqboxheading {
  background: #F2AB60;
  padding-left: 10px;
  margin-right: -40px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.small{
  font-size: 1rem;
  color: #666666;
}

.error{
  font-size: 10pt !important;
  font-family: 'gotham';
  color: #AA6666;
  font-weight: normal;
  text-align: left;
}

.anchor {
  font-size: 10pt !important;
  font-family: 'gothamXLight';
  color: '#98511E' !important;
  text-decoration: none;
}
.anchor:hover{
  text-decoration: underline;
}

h5 {
  font-size: 1.1rem;
  font-family: 'Ubuntu', sans-serif;
  color: #98511E;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

h6{ 
  font-size: 0.8rem;
}

label {
  color: #98511E;
  padding: 1vw;
  margin-bottom: 1vw;
  margin: 0;
}

.buttonlink {
  background-color: inherit;
  border: none;
  color: #98511E;
}

.modal-content {
  background-color: #FBF6E4 !important;
}

.small-text {
  font-size: 0.8rem;
}

.text-danger {
  color: #98511E !important;
}

a {
  color: inherit;
}

a.brown {
  color: #98511E;
}

p {
  font-size: 1rem;
}

@media only screen and (min-width: 901px) {
  
  .cartText {
    color: #564020;
    font-size: 1.2rem;
    padding: 1px;
    margin-bottom: 1vw;
    margin: 0;
  }

  .cartTitle {
    color: #98511E;
    font-size: 1.2rem;
    padding: 1px;
    margin-bottom: 1vw;
    margin: 0;
  }

  .mobile-container {
    width: 750px;
    margin: auto
  }

  h3 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 900px) {

  .cartText {
    color: #564020;
    margin-bottom: 1vw;
    padding: 1px;
    margin: 0;
  }

  .cartTitle {
    color: #98511E;
    margin-bottom: 1vw;
    padding: 1px;
    margin: 0;
  }

  .mobile-container {
    width: 100%;
    margin: auto
  }

  h3 {
    font-size: 1.3rem !important;
  }

  
}

.h2{
  background-color: #E2D39C;
  border-top-color: #D2B883;
  border-top-width: 2px; 
  border-top-style: solid;
  border-bottom-color: #D2B883;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  padding: 2px;
  padding-bottom: 3px;
  font-size: 1.3rem;
}

.color-brown {
  color: #98511E;
}

.color-brown-light {
  color: #BF8B48;
}